<template>
  <div v-if="scrollY">
    <v-app-bar app :height="xsOnly ? 57 : 66" :dense="!!scrollY" style="z-index: 7">
      <!-- <v-app-bar-nav-icon v-model="drawer" @click="drawer = !drawer" class="grey--text text--darken-4 ml-n1 mr-1"></v-app-bar-nav-icon> -->
      <div class="d-flex align-center" @click="$router.push('/')">
        <v-avatar class="ma-1 mb-0" size="40">
          <img src="@/assets/logo.jpg" alt="YelagiriGuide">
        </v-avatar>
        <v-toolbar-title
          class="font-weight-black pa-2 pb-1 grey--text text--darken-4 cursor-pointer"
        > YelagiriGuide </v-toolbar-title>
      </div>
      <v-spacer></v-spacer>
      <v-layout row wrap align-center justify-end>
        <v-flex lg1 xs5 class="text-right">
          <v-btn icon @click="loginHandler">
            <v-icon color="grey darken-3"> mdi-login-variant </v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-card tile flat>
        <v-img src="favicon.ico" alt="YelagiriGuide" height="200" width="100%"></v-img>
      </v-card>
      <v-list dense class="py-0">
        <v-list-item-group v-model="group" active-class="grey--text text--darken-4" mandatory>
          <template v-for="(item, index) in sideMenuItems">
            <v-list-item :key="index" class="px-5 py-1" @click="item.atClick && item.atClick(); drawer = false;" v-if="!item.hide">
              <!-- @click="$route.fullPath !== item.to ? $router.push(item.to || '/') : ''"> -->
              <v-list-item-icon class="ml-1 ml-lg-0 mr-5">
                <v-icon :size="item.iconSize || 24"> {{ item.icon }} </v-icon>
              </v-list-item-icon>
              <v-list-item-title class="subtitle-2 font-weight-bold"> {{ item.title }} </v-list-item-title>
            </v-list-item>
          </template>
        </v-list-item-group>
      </v-list>
      <!-- <template #append>
        <v-btn block> Login </v-btn>
      </template> -->
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  data () {
    return {
      drawer: false,
      group: null,
      scrollY: 0
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      this.scrollY = scrollY
    })
  },
  computed: {
    sideMenuItems () {
      return [{
        title: 'Home',
        icon: 'mdi-home',
        iconSize: 26,
        atClick: () => this.$_scrollIntoView('body')
      }, {
        title: 'Blogs',
        icon: 'mdi-bookmark-multiple',
        iconSize: 22,
        atClick: () => {},
        // hide: true
      }, {
        title: 'Rooms',
        icon: 'mdi-bed',
        iconSize: 24,
        atClick: () => {},
        hide: true
      }, {
        title: 'Camping/ Trekking',
        icon: 'mdi-campfire',
        iconSize: 25,
        atClick: () => {},
        hide: true
      }, {
        title: 'Popular Destinations',
        icon: 'mdi-shape-plus',
        iconSize: 23,
        atClick: () => {},
        hide: true
      }, {
        title: 'Contact us',
        icon: 'mdi-headset',
        iconSize: 23,
        atClick: () => this.$_scrollIntoView('.contact-us')
      }]
    }
  },
  methods: {
    loginHandler () {
      window.open('http://portal.yelagiriguide.in/', 'blank')
    }
  }
}
</script>